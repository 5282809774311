import React, { Component } from "react";
import PricingCard from "../PricingCard/PriceCard";

export default class StandardPackage extends Component {
  state = {
    data: [
      {
        Package: [
          {
            Level: "Standard",
            Description:
              "Upgrade your online presence with a custom design & advanced SEO. Perfect for small businesses.",
          },
        ],
        Data: [
          {
            Price: "349",
            PayFrequency: "month",
          },
        ],
        Features: [
          { Title: "", Content: "Custom design" },
          { Title: "", Content: "Advanced on-page SEO" },
          { Title: "", Content: "Contact form" },
          { Title: "", Content: "Google Analytics integration" },
          { Title: "", Content: "Blog integration" },
        ],
        Action: [{ ButtonTitle: "Sign Up", PushPath: "standard" }],
      },
    ],
  };
  render() {
    const { Package, Data, Features, Action } = this.state.data[0];
    return (
      <>
        <div className="col">
          <PricingCard
            Package={Package}
            Data={Data}
            Features={Features}
            Action={Action}
          />
        </div>
      </>
    );
  }
}
