import React, { Component } from "react";

export default class ToolCard extends Component {
  state = {};
  render() {
    const {
      toolImg,
      toolInitials,
      toolName,
      toolUseCase,
      toolDocumentationLink,
    } = this.props;
    return (
      <div
        className="ToolCard"
        style={{
          backgroundColor: "blue",
          margin: "20px",
          display: "flex",
          height: "200px",
        }}
      >
        <img
          className="ToolCardImage"
          src={toolImg}
          alt={toolInitials}
          height="200px"
          width="200px"
        />
        <div
          className="ToolCardInfo"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <p className="ToolCardName">{toolName}</p>
          <p className="ToolCardIntials">{toolInitials}</p>
          <p className="ToolCardUseCase">{toolUseCase}</p>
          <p className="ToolCardDocLink">{toolDocumentationLink}</p>
        </div>
      </div>
    );
  }
}
