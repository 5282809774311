import React, { Component } from "react";

import { Link, Outlet } from "react-router-dom";

export default class TheResults extends Component {
  state = {};
  render() {
    return (
      <div className="Global_AboutPageSection6Card">
        <div className="Global_Introduction">
          <ul className="Global_AboutPageSectionOptionsList">
            <button className="Global_AboutPageSectionOptionsListOption 1">
              <Link to="dream">The Dream</Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 2">
              <Link to="team">
                <p>The Team</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 3">
              <Link to="plan">
                <p>The Plan</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 4">
              <Link to="work">
                <p>The Work</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 5">
              <Link to="tools">
                <p>The Tools</p>
              </Link>
            </button>
            <button
              className="Global_AboutPageSectionOptionsListOption 6"
              disabled
            >
              <Link to="results">
                <p>The Results</p>
              </Link>
            </button>
            <Outlet />
          </ul>
          <div style={{ border: "1px solid", width: "90%", height: "auto" }}>
            <div> hi </div>
          </div>
        </div>
        <h2 className="Global_AboutPageSection3CardTitle">The Results</h2>
        <div className="TestBlock">
          <div className="Global_AboutPageWorkSection6Items">
            <ul>
              <h1>Updates & News</h1>
              <h2>ChangeLog</h2>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
