import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class CardBody extends Component {
  render() {
    const { Data } = this.props;
    return (
      <>
        <div className="card-body">
          <ul className="list-group list-group-flush">
            {Data.map((item, index) => (
              <li className="list-group-item" key={index}>
                {item.Direction === "INBOUND" ? (
                  <Link to={`/${item.Path}`} style={{ textDecoration: "none" }}>
                    {item.Title}
                  </Link>
                ) : (
                  <a
                    href={`https://www.${item.Path}${item.TLD}`}
                    style={{ textDecoration: "none" }}
                  >
                    {item.Title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
}
