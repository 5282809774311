import React, { Component } from "react";

class SearchConfirm extends Component {
  state = {};
  render() {
    const { Data } = this.props;
    return (
      <>
        <button className="btn btn-outline-success" type="submit">
          Search
        </button>
      </>
    );
  }
}

export default SearchConfirm;
