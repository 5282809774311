import React, { Component } from "react";
import { PricingCard } from "./Parts/PricingCard/PricingCard";
import { Link } from "react-router-dom";
import WebPricingInfo from "./Parts/webPricingInfo";

export default class GlobalMainSection extends Component {
  state = { Styles: { height: "auto" }, Data: "700.953" };

  render() {
    const { Styles } = this.state;
    // const onTabSwitch = () => {};
    // const handleTabSwtich = () => {};
    return (
      <>
        <div
          className="card text-center list-group list-group-item list-group-flush"
          style={Styles}
        >
          <div className=" card-header list-group-item ">
            <ul
              className="nav nav-tabs card-header-tabs"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <Link
                  className="nav-link active"
                  aria-current="true"
                  id="WebDesign-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#WebDesign-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="WebDesign-tab-pane"
                  aria-selected="false"
                >
                  Web-Design
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="contact-tab-pane"
                  aria-selected="false"
                >
                  Discord
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link
                  className="nav-link disabled"
                  id="disabled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#disabled-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="disabled-tab-pane"
                  aria-selected="false"
                  disabled
                >
                  Disabled
                </Link>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <WebPricingInfo />
          </div>
        </div>
      </>
    );
  }
}
