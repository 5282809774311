import React, { Component } from "react";
import { Link } from "react-router-dom";
import DarkModeSwitch from "../../Specials/DarkModeSwitch/DarkModeSwitch";
import PageRoutes from "./Parts/pageRoutes";

export default class GlobalNavbar extends Component {
  state = { height: { height: "auto" } };
  render() {
    const { height } = this.state;
    return (
      <>
        <div
          id="Global_NavbarSection"
          className="list-group list-group-flush list-group-item"
          style={height}
        >
          <nav className="card navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
              <Link className="navbar-brand m-2" to={"/"}>
                BorDev Tech
              </Link>
              <button
                className="navbar-toggler m-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto m-2">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/about"}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/services"}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/projects"}
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/contact"}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <form className="d-flex m-2" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  list="datalistOptions"
                  placeholder="Search"
                  aria-label="Search"
                />
                <datalist id="datalistOptions">
                  <PageRoutes />
                  <option value="San Francisco" />
                  <option value="New York" />
                  <option value="Seattle" />
                  <option value="Los Angeles" />
                  <option value="Chicago" />
                </datalist>
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </form>
              <button className="btn btn-danger m-2" type="submit">
                Get Started
              </button>
              <DarkModeSwitch
                elmId="root"
                lightModeColor="white"
                darkModeColor="#1C1C1E"
              />
            </div>
          </nav>
        </div>
      </>
    );
  }
}
