//Desktop View
import '../pages/ConstructionPage/page/ConstructionPage.css'
// Mobile View
import '../pages/ConstructionPage/page/css/mobile.css'

import RedirectMap from "../support/Atlas";
import PageInformation from '../_COMPONENTS/PageInformation/PageInformation';


export default function ConstructionPage() {
    return (
        <>
            <PageInformation id='PageInformation' pageName='SiteMap' />
            <div className='ConstructionPage'>
                <div className='Warning'>
                    <div className='WarningArea'>
                        <div className='WarningSymbol'>
                            ⚠️🛑⚠️
                        </div>
                        <div className='WarningCode'>
                            <div className='WarningNumber'>303</div>
                            <div className='WarningLabel'>
                                <div>Page Under Construction</div>
                            </div>
                            <div className='WarningComment'>
                                <p>Nothing to see here.. Below is a map of the available redirects.</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <RedirectMap />
            </div>
        </>
    );
}