import React, { Component } from "react";

class CardFooter extends Component {
  render() {
    const { LastUpdate } = this.props;
    return (
      <div className="card-footer">
        <small className="text-muted">{LastUpdate}</small>
      </div>
    );
  }
}

export default CardFooter;
