import React, { Component } from "react";

import { Link } from "react-router-dom";

export default class CardTitle extends Component {
  render() {
    const { Title, Path, Direction, TLD } = this.props;
    return (
      <>
        <div className="card-header">
          {Direction === "INBOUND" ? (
            <h5>
              <Link to={`/${Path}`} style={{ textDecoration: "none" }}>
                {Title}
              </Link>
            </h5>
          ) : (
            <h5>
              <a
                href={`https://www.${Path}${TLD}`}
                style={{ textDecoration: "none" }}
              >
                {Title}
              </a>
            </h5>
          )}
        </div>
      </>
    );
  }
}
