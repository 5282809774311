import React from "react";
import SearchInput from "./searchInput";
import SearchData from "./searchData";
import SearchConfirm from "./searchConfirm";

const SearchBar = (Data) => {
  return (
    <form className="d-flex m-2" role="search">
      <SearchInput />
      <SearchData Data={Data} />
      <SearchConfirm />
    </form>
  );
};

export default SearchBar;
