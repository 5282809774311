import React from "react";
//Sections


function GlobalDreamSection() {
    return (
        <>
            <div className='Global_AboutPage_Dream_' style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 className='Global_AboutPage_Dream_' style={{}}>The Dream</h2>
                <div className='Global_AboutPage_Dream_' style={{}}>
                    <img
                        className='Global_AboutPage_Dream_' style={{}}
                        scr='#' alt='Global_AboutPageSection1Image' />
                    <p className='Global_AboutPage_Dream_' style={{}}>
                        "Having a great passion for all knowledge of Business and Programming, I decided to create a business model that helps others Understand and Create with the flow of entrepreneurship to bring their own business ideas to life. "
                    </p>
                    <div className='Global_AboutPage_Dream_' style={{}}><img scr='#' alt='TeamMemberImage'></img>
                        <p className='TeamMemberTitle'>
                            Creator: Devell Robinson
                        </p>
                        <p className='TeamMemberBioShort'>
                            "Having a great passion for all knowledge of Business and Programming, I decided to create a business model that helps others Understand and Create with the flow of entrepreneurship to bring their own business ideas to life. "
                        </p>
                        <p className='TeamMemberQuoted'>
                            - Devell
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GlobalDreamSection;