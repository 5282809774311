import React, { Component } from "react";

import { Link, Outlet } from "react-router-dom";
import ToolCard from "../../../../_COMPONENTS/Specials/ToolCards/ToolCard";

export default class TheTools extends Component {
  state = {};
  render() {
    return (
      <div className="Global_AboutPageSection5Card">
        <div className="Global_Introduction">
          <ul className="Global_AboutPageSectionOptionsList">
            <button className="Global_AboutPageSectionOptionsListOption 1">
              <Link to="dream">The Dream</Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 2">
              <Link to="team">
                <p>The Team</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 3">
              <Link to="plan">
                <p>The Plan</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 4">
              <Link to="work">
                <p>The Work</p>
              </Link>
            </button>
            <button
              className="Global_AboutPageSectionOptionsListOption 5"
              disabled
            >
              <Link to="tools">
                <p>The Tools</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 6">
              <Link to="results">
                <p>The Results</p>
              </Link>
            </button>
            <Outlet />
          </ul>
          <div style={{ border: "1px solid", width: "90%", height: "auto" }}>
            <div> hi </div>
          </div>
        </div>
        <h2 className="Global_AboutPageSection3CardTitle">The Tools</h2>
        <div className="TestBlock2">
          <ToolCard
            toolImg="React"
            toolName="React"
            toolIntials="React.JS"
            toolUseCase="React"
            toolDocumentationLink="React"
          />
          <ToolCard
            toolImg="JavaScript"
            toolName="JavaScript"
            toolIntials="JS"
            toolUseCase="JavaScript"
            toolDocumentationLink="JavaScript"
          />
          <ToolCard
            toolImg="HTMl"
            toolName="HyperTextMarkdownLanguage"
            toolIntials="HTML"
            toolUseCase="HTML"
            toolDocumentationLink="HTMl"
          />
          <ToolCard
            toolImg="CSS"
            toolName="Cascading Style Sheets"
            toolIntials="CSS"
            toolUseCase="CSS"
            toolDocumentationLink="CSS"
          />
          <div className="Global_AboutPageWorkSection5Items"></div>
          {/* <p>Make Money, by helping you make money.</p> */}
        </div>
      </div>
    );
  }
}
