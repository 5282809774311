import React from 'react';
//Components
import PageInformation from '../_COMPONENTS/PageInformation/PageInformation';
import NavbarSection from '../_COMPONENTS/Navbar/Navbar';
import FooterSection from '../_COMPONENTS/Footer/Footer';
import MainSection from './ContactPage/components/mainSection.jsx';
//Sections
//CSS
import './ContactPage/page/ContactPage.css'


export default function ContactPage() {
    return (
        <>
            <PageInformation id='PageInformation' pageName='Contact Us' />
            {/*Sections */}
            <NavbarSection id='NavbarSection' />
            <MainSection id='MainSection' />
            <FooterSection id='FooterSection' />
        </>
    );

}