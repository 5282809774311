//IMPORTS
import React from 'react';
//////////////////////////////////////////////////////
// VIEWPORT SIZES CSS
/* CSS */
import './Views/CSS/SignUpPage.css';
/* Global View */
import GlobalMainSection from './Views/Global_MainSection';
/* Tablet View */
import TabletMainSection from './Views/Tablet_MainSection.jsx';
/* Mobile View */
import MobileMainSection from './Views/Mobile_MainSection.jsx';
//////////////////////////////////////////////////////

function MainSection() {
    return (

        <>
            <GlobalMainSection id='GlobalMainSection' />
            <TabletMainSection id='TabletMainSection' />
            <MobileMainSection id='MobileMainSection' />
        </>
    );
}

export default MainSection;






