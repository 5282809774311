//IMPORTS
import React, { Component } from "react";

//////////////////////////////////////////////////////
// VIEWPORT SIZES CSS
/* CSS */
import "./Views/CSS/AboutPage.css";
/* Global View */
import GlobalMainSection from "./Views/Global_MainSection";
/* Tablet View */
import TabletMainSection from "./Views/Tablet_MainSection";
/* Mobile View */
import MobileMainSection from "./Views/Mobile_MainSection";
//////////////////////////////////////////////////////

export default class MainSection extends Component {
  state = {};
  render() {
    return (
      <>
        <GlobalMainSection id="GlobalMainSection" />
        <TabletMainSection id="TabletMainSection" />
        <MobileMainSection id="MobileMainSection" />
      </>
    );
  }
}
