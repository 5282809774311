import React, { Component } from 'react';
import PageInformation from '../_COMPONENTS/PageInformation/PageInformation';

import NavbarSection from '../_COMPONENTS/Navbar/Navbar';
import MainSection from './ProjectsPage/MainSection/MainSection';
import FooterSection from '../_COMPONENTS/Footer/Footer';




export default class ProjectsPage extends Component {
    state = {}
    render() {
        return (
            <>
                <PageInformation id='PageInformation' pageName='Projects' />

                {/*Sections */}
                <NavbarSection id='NavbarSection' />
                <MainSection id='MainSection' />
                <FooterSection id='FooterSection' />
            </>);
    }
}

