import React, { Component } from "react";
import PricingCard from "./PricingCard/PriceCard";
import MaintenancePackage from "./Packages/MaintenancePackage";
import BasicPackage from "./Packages/BasicPackage";
import StandardPackage from "./Packages/StandardPackage";
import PremiumPackage from "./Packages/PremiumPackage";

export default class WebPricingInfo extends Component {
  state = {
    data: [{}],
  };
  render() {
    return (
      <>
        <div
          className="tab-pane fade show active"
          id="WebDesign-tab-pane"
          role="tabpanel"
          aria-labelledby="WebDesign-tab"
          tabIndex="0"
        >
          <div className="card-body list-group list-group-flush">
            <h1 className="text-center mb">Our Pricing Packages</h1>
            <div className="row row-cols-1 row-cols-md-4">
              <BasicPackage />
              <StandardPackage />
              <PremiumPackage />
              <MaintenancePackage />
            </div>
          </div>
        </div>
      </>
    );
  }
}
