import React, { Component } from "react";

export default class PageInformation extends Component {
  state = { Data: { CompanyInitials: "BDT" } };
  render() {
    const { pageName } = this.props;
    return (
      <>
        <meta charSet="UTF-8" className="PageInformation" />
        <meta
          httpEquiv="X-UA-Compatible"
          content="IE=edge"
          className="PageInformation"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
          className="PageInformation"
        />
        <title className="PageInformation">
          {this.state.Data.CompanyInitials} | {pageName}
        </title>
        <link
          rel="stylesheet"
          href="../CSS/index.css"
          className="PageInformation"
        />
        <link rel="icon" href="./Parts/favicon.ico" />
      </>
    );
  }
}
