import React, { Component } from "react";

class SearchData extends Component {
  state = {};
  render() {
    const { Data } = this.props;
    return (
      <>
        <datalist id="datalistOptions">
          <option value="A" />
          <option value="B" />
          <option value="C" />
          <option value="D" />
          <option value="E" />
        </datalist>
      </>
    );
  }
}

export default SearchData;
