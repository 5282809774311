import React from "react";
import PriceInfo from "./PriceInfo";
import PriceActionButton from "./PriceActionButton";
const PricingCard = ({ Package, Data, Features, Action }) => {
  return (
    <>
      <div className="card h-100">
        <PriceInfo Package={Package} Data={Data} Features={Features} />
        <PriceActionButton Action={Action} />
      </div>
    </>
  );
};

export default PricingCard;
