import React, { Component } from "react";

import { Link, Outlet } from "react-router-dom";
import TeamMemberCard from "../../../../_COMPONENTS/Specials/TeamMemberCards/TeamMemberCard";

export default class TheTeam extends Component {
  state = {};
  render() {
    return (
      <div className="GlobalAboutPageSection2Card">
        <div className="Global_Introduction">
          <ul className="Global_AboutPageSectionOptionsList">
            <button className="Global_AboutPageSectionOptionsListOption 1">
              <Link to="dream">The Dream</Link>
            </button>
            <button
              className="Global_AboutPageSectionOptionsListOption 2"
              disabled
            >
              <Link to="team">
                <p>The Team</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 3">
              <Link to="plan">
                <p>The Plan</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 4">
              <Link to="work">
                <p>The Work</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 5">
              <Link to="tools">
                <p>The Tools</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 6">
              <Link to="results">
                <p>The Results</p>
              </Link>
            </button>
            <Outlet />
          </ul>
          <div style={{ border: "1px solid", width: "90%", height: "auto" }}>
            <div> hi </div>
          </div>
        </div>
        <h2 className="GlobalAboutPageSection2CardTitle">The Team</h2>
        <div className="GlobalAboutPageSection2CardItems">
          <TeamMemberCard
            image="#"
            positionTitle="Creator, Founder"
            memberName="Devell Robinson"
            memberBioShort='"Having a great passion for all knowledge of Business and Programming, I decided to create a business model that helps others Understand and Create with the flow of entrepreneurship to bring their own business ideas to life. "'
            memberQuoteIntials="-D.R"
            memberJoined="07/25/2021"
          />
          <TeamMemberCard
            image="#"
            positionTitle="Co-Founder"
            memberName="Danielle Robinson"
            memberBioShort='"I just like to help people LOL. "'
            memberQuoteIntials="-D.R"
            memberJoined="02/11/2022"
          />
          <TeamMemberCard
            image="#"
            positionTitle="Co-Founder"
            memberName="Jaxson Stevens"
            memberBioShort="test"
            memberQuoteIntials="-J.S"
            memberJoined="02/11/2022"
          />
          <TeamMemberCard
            image="#"
            positionTitle="Co-Founder"
            memberName="Khalil Robinson"
            memberBioShort="test"
            memberQuoteIntials="-K.R"
            memberJoined="02/11/2022"
          />
        </div>
      </div>
    );
  }
}
