import React from "react";
import { Link } from "react-router-dom";

export const PricingCard = ({
  Title,
  Price,
  Description,
  Ft1,
  Ft2,
  Ft3,
  LinkTitle,
  LinkPath,
}) => {
  return (
    <div className="card h-100">
      <div className="card-header">
        <h2 className="text-center">{Title}</h2>
      </div>
      <div className="card-body">
        <h5 className="text-center">{`$${Price}/month`}</h5>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <p className="card-text ">{Description}</p>
          </li>
          <li className="list-group-item">
            <h6 className="card-subtitle mb-2 text-muted">Feature 1</h6>
            <p className="card-text">{Ft1}</p>
          </li>
          <li className="list-group-item">
            <h6 className="card-subtitle mb-2 text-muted">Feature 2</h6>

            <p className="card-text ">{Ft2}</p>
          </li>
          <li className="list-group-item">
            <h6 className="card-subtitle mb-2 text-muted">Feature 3</h6>
            <p className="card-text ">{Ft3}</p>
          </li>
        </ul>
      </div>
      <div className="card-footer text-center">
        <Link to={`/${LinkPath}`} className="btn btn-primary">
          {LinkTitle}
        </Link>
      </div>
    </div>
  );
};
