import React, { Component } from "react";
import PriceFeats from "./PriceFeats";

class PriceInfo extends Component {
  state = {};
  render() {
    const { Package, Data, Features } = this.props;
    return (
      <>
        {Package.map((Package) => {
          const { Level, Description } = Package;
          return (
            <>
              <div className="card-header">
                <h2 className="text-center">{Level}</h2>
              </div>
              <div className="card-body">
                <h5 className="text-center">
                  {Data.map((Data) => {
                    const { Price, PayFrequency } = Data;
                    return (
                      <>
                        ${Price}/{PayFrequency}
                      </>
                    );
                  })}
                </h5>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">{Description}</li>

                  <PriceFeats Features={Features} />
                </ul>
              </div>
            </>
          );
        })}
      </>
    );
  }
}

export default PriceInfo;
