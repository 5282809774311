export default function ErrorPage() {
    return (
        <div>
            <ul>
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>You seem lost.. would likea hand to navigate to the home page?</p>
                <button>Yes, Take me there!</button>

            </ul>

        </div>
    );
}