// REQ Imports
import React, { Component } from "react";

import { Link, Outlet } from "react-router-dom";
// Section Cards

export default class TabletMainSection extends Component {
  state = {};
  render() {
    return (
      <div id="Tablet_AboutPageMainSection">
        <Outlet />{" "}
      </div>
    );
  }
}
