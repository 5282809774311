import React, { Component } from "react";

import "./screenDisplay.css";
import { Route, Routes } from "react-router-dom";
///////////////////////////////////////////////////

import HomePage from "./pages/HomePage.js";
import AboutPage from "./pages/AboutPage.js";
import TheDream from "./pages/AboutPage/Parts/Dream/TheDream";
import TheTeam from "./pages/AboutPage/Parts/Team/TheTeam";
import ThePlan from "./pages/AboutPage/Parts/Plan/ThePlan";
import TheWork from "./pages/AboutPage/Parts/Work/TheWork";
import TheTools from "./pages/AboutPage/Parts/Tools/TheTools";
import TheResults from "./pages/AboutPage/Parts/Results/TheResults";
import ContactPage from "./pages/ContactPage.js";
import CompanyPage from "./pages/CompanyPage.js";
import ProjectsPage from "./pages/ProjectsPage.js";
import ProductsPage from "./pages/ProductsPage.js";
import ServicesPage from "./pages/ServicesPage.js";
import SocialsPage from "./pages/SocialsPage.js";
import SupportPage from "./pages/SupportPage.js";
// import GetStartedPage from "./pages/GetStartedPage.js";
import SignupPage from "./pages/SignupPage.js";
import PricingPage from "./pages/PricingPage.js";
import SponsorPage from "./pages/SponsorsPage.js";
import Sponsor from "./pages/Sponsor.js";
import ErrorPage from "./pages/ErrorPage.js";
import ConstructionPage from "./pages/ConstructionPage.js";

/*<ScreenDisplay />*/
/*USE SCREENDISPLAY WHEN FILE SWITCH MECHANISM IS CREATED*/
export default class ScreenDisplay extends Component {
  state = {};
  render() {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    console.log(`Viewport size: ${viewportWidth} x ${viewportHeight}`);
    return (
      <div id="ScreenDisplay" className="list-group">
        {/* INCORPERATE 'Helping your business progress because we care about your success' AS SLOGAN */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/getstarted" element={<SignupPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/about/dream" element={<TheDream />} />
          <Route path="/about/team" element={<TheTeam />} />
          <Route path="/about/plan" element={<ThePlan />} />
          <Route path="/about/work" element={<TheWork />} />
          <Route path="/about/tools" element={<TheTools />} />
          <Route path="/about/results" element={<TheResults />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/socials" element={<SocialsPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/sponsors" element={<SponsorPage />} />
          <Route path="/sponsors/:aka" element={<Sponsor />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/construction" element={<ConstructionPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/pricing/discord" exact element={<PricingPage />} />
          <Route path="/pricing/webdesign" element={<PricingPage />} />
        </Routes>
      </div>
    );
  }
}
