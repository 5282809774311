import React, { Component } from "react";
import Card from "../../../../../_COMPONENTS/Common/SectionCards/card";

export default class SupportSectionCard extends Component {
  state = {
    data: [
      {
        Name: [
          { Header: "Support", Path: "support", Direction: "INBOUND", TLD: "" },
        ],
        Data: [
          {
            Title: "Guides",
            Path: "guides",
            Direction: "INBOUND",
            TLD: "",
          },
          {
            Title: "Blogs",
            Path: "blogs",
            Direction: "INBOUND",
            TLD: ".com",
          },
          {
            Title: "Contact Us",
            Path: "contactus",
            Direction: "INBOUND",
            TLD: ".com",
          },
          {
            Title: "COMING SOON...",
            Path: "",
            Direction: "INBOUND",
            TLD: "",
          },
        ],
        Info: [{ LastUpdate: " Last updated 3 mins ago " }],
      },
    ],
  };
  render() {
    const { Name, Data, Info } = this.state.data[0];
    const { Header, Path, Direction, TLD } = Name[0];
    const { LastUpdate } = Info[0];
    return (
      <>
        <Card
          Title={Header}
          Data={Data}
          Path={Path}
          Direction={Direction}
          TLD={TLD}
          LastUpdate={LastUpdate}
        />
      </>
    );
  }
}
