
import React from 'react';
import MainSection from './SignupPage/MainSection/MainSection';
import PageInformation from '../_COMPONENTS/PageInformation/PageInformation';

export default function SignupPage() {

    return (
        <>
            <PageInformation id='PageInformation' pageName='Sign Up' />
            <MainSection />
        </>
    );

}