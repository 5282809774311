import React, { Component } from "react";

class PriceFeats extends Component {
  state = {};
  render() {
    const { Features } = this.props;
    return (
      <>
        {Features.map((Feature) => {
          const { Content, Title } = Feature;
          return (
            <>
              <li className="list-group-item">
                <h6 className="card-subtitle mb-2 text-muted">{Title}</h6>
                <p className="card-text">{Content}</p>
              </li>
            </>
          );
        })}
      </>
    );
  }
}

export default PriceFeats;
