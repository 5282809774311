import React, { Component } from "react";

export default class TeamCard extends Component {
  render() {
    const { position, name } = this.props;
    return (
      <div>
        <button id="test">
          <h3>{name}</h3>
          <h4>{position}</h4>
        </button>
      </div>
    );
  }
}
