import React, { Component } from "react";

import { Link } from "react-router-dom";

export default class GlobalMainSection extends Component {
  state = {};
  render() {
    return (
      <>
        <ul
          id="Tablet_HomePageMainSection"
          className=" text-center flex-fill list-group list-group-flush list-group-item"
        >
          <li className=" list-group-item justify-content-center align-items-center">
            <div className="  card card-body">
              <h5 className="card-title">Welcome !</h5>
              <p className="card-text">
                Here at BorDev Tech we provide top-notch service to get you on
                your feet and ready to go!
              </p>
              <p className="card-text">
                Our wide range of experience helps us, help you in building
                anything you can dream of.
              </p>

              <div className="btn-group nav justify-content-center gap-2 ">
                <Link to="/signup" className="btn btn-danger col-2 btn-md">
                  Get Started
                </Link>

                <div className="vr"></div>
                <Link to="/pricing" className="btn btn-danger col-2 btn-md">
                  Pricing
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </>
    );
  }
}
