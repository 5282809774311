import React from "react";

import { Link, Outlet } from "react-router-dom";


function TheWork() {
    return (
        < div className='Global_AboutPageSection4Card' >
            <div className='Global_Introduction'>
                <ul className='Global_AboutPageSectionOptionsList' >
                    <button className='Global_AboutPageSectionOptionsListOption 1'>
                        <Link to='dream'>The Dream</Link>
                    </button>
                    <button className='Global_AboutPageSectionOptionsListOption 2'>
                        <Link to='team'><p>The Team</p></Link>
                    </button>
                    <button className='Global_AboutPageSectionOptionsListOption 3'>
                        <Link to='plan'><p>The Plan</p></Link>
                    </button>
                    <button className='Global_AboutPageSectionOptionsListOption 4' disabled>
                        <Link to='work'><p>The Work</p></Link>
                    </button>
                    <button className='Global_AboutPageSectionOptionsListOption 5'>
                        <Link to='tools'><p>The Tools</p></Link>
                    </button>
                    <button className='Global_AboutPageSectionOptionsListOption 6'>
                        <Link to='results'><p>The Results</p></Link>
                    </button>
                    <Outlet />
                </ul >
                <div style={{ border: '1px solid', width: '90%', height: 'auto' }}>
                    <div> hi </div>
                </div>
            </div >
            <h2 className='Global_AboutPageSection4CardTitle'>The Work</h2>
            <div className='TestBlock' >
                <ul>
                    <div className='Global_AboutPageWorkSection4Items'>
                        <img scr='#' alt='Section4Image' className='Global_AboutPageSection4PointImage'></img>
                        <p className='Global_AboutPageSection4PointTitle'>Step 1: Research</p>
                        <p className='Global_AboutPageSection4PointBioShort'>All projects start out from an idea, its up to you and us to figure out how to make it work.</p>
                    </div>
                </ul>

                <ul>
                    <div className='Global_AboutPageWorkSection4Items'>
                        <img scr='#' alt='Section4Image' className='Global_AboutPageSection4PointImage'></img>
                        <p className='Global_AboutPageSection4PointTitle'>Step 2: Validate</p>
                        <p className='Global_AboutPageSection4PointBioShort'>Now we've figured out possible solutions to make it work. The next step is to filter all the good and bad.</p>
                    </div>
                </ul >
            </div>
        </div >
    );
}
export default TheWork;