import React, { Component } from "react";
import PricingCard from "../PricingCard/PriceCard";

export default class MaintenancePackage extends Component {
  state = {
    data: [
      {
        Package: [
          {
            Level: "Maintenance",
            Description:
              "Keep your website updated, secure & optimized with our maintenance package. For established businesses.",
          },
        ],
        Data: [
          {
            Price: "99.99",
            PayFrequency: "month",
          },
        ],
        Features: [
          {
            Title: "Feature",
            Content: "Regular website/content updates (if provided by client)",
          },
          { Title: "Feature", Content: "Performance monitoring" },
          {
            Title: "Feature",
            Content: "Daily backups",
          },
          { Title: "Feature", Content: "Security patches" },
          { Title: "Feature", Content: "Email and phone support" },
        ],
        Action: [{ ButtonTitle: "Sign Up", PushPath: "maintenance" }],
      },
    ],
  };
  render() {
    const { Package, Data, Features, Action } = this.state.data[0];
    return (
      <>
        <div className="col">
          <PricingCard
            Package={Package}
            Data={Data}
            Features={Features}
            Action={Action}
          />
        </div>
      </>
    );
  }
}
