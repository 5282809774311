import React, { useState } from "react";

function DarkModeSwitch(props) {
  const [mode, setMode] = useState("dark"); // set initial mode to dark

  const handleDarkMode = () => {
    setMode("dark");
    document.getElementById(props.elmId).style.background = "#161618";
  };

  const handleLightMode = () => {
    setMode("light");
    document.getElementById(props.elmId).style.background = "white";
  };

  return (
    <>
      {mode === "light" ? (
        <button onClick={handleDarkMode} className="btn btn-dark">
          ☀️
        </button>
      ) : (
        <button onClick={handleLightMode} className="btn btn-primary">
          🌙
        </button>
      )}
    </>
  );
}

export default DarkModeSwitch;
