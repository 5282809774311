import React, { Component } from "react";

export default class TeamMemberCard extends Component {
  state = {};
  render() {
    const {
      image,
      memberName,
      positionTitle,
      memberBioShort,
      memberJoined,
      memberQuoteIntials,
    } = this.props;
    return (
      <div
        className="TeamMemberCard"
        style={{
          backgroundColor: "blue",
          margin: "20px",
          display: "flex",
          height: "200px",
        }}
      >
        <img
          className="TeamMemberCardImage"
          src={image}
          alt={memberName}
          height="200px"
          width="200px"
        />
        <div
          className="TeamMemberCardInfo"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <p className="TeamMemberCardPositionTitle">
            {"teamMember" && positionTitle}
          </p>
          <p className="TeamMemberCardName">{memberName}</p>
          <p className="TeamMemberCardBioShort">{memberBioShort}</p>
          <div
            className="TeamMemberCardSignature"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <p className="TeamMemberCardJoinedDate">{memberJoined}</p>
            <p className="TeamMemberCardIntials">{memberQuoteIntials}</p>
          </div>
        </div>
      </div>
    );
  }
}
