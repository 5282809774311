import React, { Component } from 'react';


class PageRoutes extends Component {
    state = {
        Data: {
            Pages: ["a", "b", "c"],
            RouteLinks: ["alpha", "bravo", "charlie"]
        }
    }
    render() {
        return (
            <>
            </>
        )
    }
    ;
}

export default PageRoutes;