import React from 'react';
//Components
import PageInformation from '../_COMPONENTS/PageInformation/PageInformation';
import NavbarSection from '../_COMPONENTS/Navbar/Navbar';
import FooterSection from '../_COMPONENTS/Footer/Footer';
import MainSection from './SupportPage/components/mainSection.jsx';
//Sections
//CSS
import './SupportPage/page/SupportPage.css'


export default function SupportPage() {
    return (
        <>
            <PageInformation id='PageInformation' pageName='Support' />
            {/*Sections */}
            <NavbarSection id='NavbarSection' />
            <MainSection id='MainSection' />
            <FooterSection id='FooterSection' />
        </>
    );

}