import React, { Component } from "react";

// import { Link } from "react-router-dom";
// import { FooterLinkLocal, FooterLinkOutBound } from "./Parts/FooterLinks";
import CompanySectionCard from "./Parts/SectionCards/companySectionCard";
import ProductsSectionCard from "./Parts/SectionCards/productsSectionCard";
import ServicesSectionCard from "./Parts/SectionCards/servicesSectionCard";
import SocialsSectionCard from "./Parts/SectionCards/socialsSectionCard";
import SupportSectionCard from "./Parts/SectionCards/supportSectionCard";

export default class MobileFooterSection extends Component {
  state = {
    styles: { height: "auto" },
    data: [
      {
        CompanyInfo: [
          {
            Name: "Bor Dev Tech",
            Year: "2022 - 2023",
            Email: "admin@bordevtech.com",
          },
        ],
      },
    ],
  };
  render() {
    const { Name, Year, Email } = this.state.data[0].CompanyInfo[0];
    return (
      <>
        <div
          id="Mobile_FooterSection"
          className="list-group list-group-flush list-group-item  card card-body "
        >
          <div className="card-group text-center justify-content-center">
            <SocialsSectionCard />
            <ProductsSectionCard />
            <ServicesSectionCard />
            <SupportSectionCard />
            <CompanySectionCard />
          </div>
          <div className="card card-footer list-group list-group-item list-group-flush text-center">
            <p className="Mobile_FooterBoxText">
              <small>
                Copyright ©{Year} {Name}
              </small>
            </p>
            <p className="Mobile_FooterBoxText">
              <small>
                Admin Contact: <span>{Email}</span>
              </small>
            </p>
            <p className="Mobile_FooterBoxText">
              <small>Privacy Policy</small>
            </p>
            <p className="Mobile_FooterBoxText">
              <small>Terms of Use</small>
            </p>
            <p className="Mobile_FooterBoxText">
              <small>Site Map</small>
            </p>
            {/* <p className="Mobile_FooterBoxText">
                  <a href="tel:+1.678.367.5727">678 367 5727</a>
                </p> */}
            {/* CopyRight when able */}
            {/*<p> user contributions licensed under CC BY-SA. rev YYYY.MM.DD._ _ _ _ _</p>*/}
            {/* Terms & Conditions */}
          </div>
        </div>
      </>
    );
  }
}
