
import { Link } from 'react-router-dom'

export default function SponsorPage() {
    return (
        <>
            <h1>sponsorPage</h1>
            <Link to='/sponsors/ICSG'>Ice Cream SG</Link>
            <Link to='/sponsors/BDT'>BorDevTech</Link>
        </>
    );
}