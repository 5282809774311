import React, { Component } from "react";

class GlobalMainSection extends Component {
  state = {
    Styles: { height: "961px" },
    data: {
      SignupForms: [
        {
          progression: [
            { Title: "Empty", ProgressMade: "1%", Status: "" },
            { Title: "1st form started", ProgressMade: "25%", Status: "" },
            { Title: "2nd form started", ProgressMade: "50%", Status: "" },
            { Title: "3rd form started", ProgressMade: "75%", Status: "" },
            { Title: "4th form started", ProgressMade: "100%", Status: "" },
          ],
        },
      ],
    },
  };
  render() {
    const { Styles } = this.state;
    return (
      <>
        <div
          className="container text-center list-group list-group-item list-group-flush"
          id="GlobalMainSection"
          style={Styles}
        >
          <div className="row">
            <div className="card col-12 ">
              <form className="">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div className="card-body justify-content-center">
                  <h5 className="card-title">Sign-up</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">An item</li>
                  <li className="list-group-item">A second item</li>
                  <li className="list-group-item">A third item</li>
                </ul>
                <div className="card-body">
                  <a href="/" className="card-link">
                    Cancel
                  </a>
                  <a href="/" className="card-link">
                    Next
                  </a>
                </div>
                <div className="col-12">
                  <form>
                    <div class="form-group">
                      <label htmlFor="email">Email address</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                      <small id="emailHelp" class="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small>
                      <label htmlFor="email">Auth Code</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                      <small id="emailHelp" class="form-text text-muted">
                        We'll sent an Auth Code to the address on file.
                      </small>
                    </div>
                    <div class="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password"
                      />
                    </div>
                    <div class="form-group">
                      <label htmlFor="password">Confirm Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="confirm_password"
                      />
                    </div>
                    <div class="form-group"></div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label class="form-check-label" for="defaultCheck1">
                        By clicking “Continue with Apple/Google/Email/SAML”
                        above, you acknowledge that you have read and
                        understood, and agree to Bor Dev Tech's Terms &
                        Conditions and Privacy Policy.
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                      />
                      <label class="form-check-label" for="defaultCheck2">
                        By clicking “Continue with Apple/Google/Email/SAML”
                        above, you acknowledge that you have read and
                        understood, and agree to Bor Dev Tech's Terms &
                        Conditions and Privacy Policy.
                      </label>
                    </div>
                  </form>
                  <form>
                    {this.state.data.SignupForms[0].progression.map(
                      (index, key) => {
                        return <>hi1 </>;
                      }
                    )}
                    <div
                      class="progress-stacked"
                      role="progressbar"
                      aria-label="Animated striped example"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div class="progress-bar " style={{ width: "10%" }} />
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        style={{ width: "2%" }}
                      />
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        style={{ width: "3%" }}
                      />
                    </div>
                    <h1 className="text-center mb">Get Started</h1>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "25%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                  </form>
                  <form>
                    <button className="btn btn-danger">Clear form</button>
                    <button
                      type="button"
                      class="btn btn-md btn-danger"
                      data-bs-toggle="popover"
                      data-bs-title="Popover title"
                      data-bs-content="And here's some amazing content. It's very engaging. Right?"
                    >
                      Delete and Exit
                    </button>
                  </form>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GlobalMainSection;
