import React, { Component } from "react";
import { Link } from "react-router-dom";

class PriceActionButton extends Component {
  state = {};
  render() {
    const { Action } = this.props;
    return (
      <>
        {Action.map((Push) => {
          const { PushPath, ButtonTitle } = Push;
          return (
            <>
              <div className="card-footer text-center">
                <Link className="btn btn-primary" to={`${PushPath}`}>
                  {ButtonTitle}
                </Link>
              </div>
            </>
          );
        })}
      </>
    );
  }
}

export default PriceActionButton;
