import React from 'react';
//Components
import PageInformation from '../_COMPONENTS/PageInformation/PageInformation.jsx';
import NavbarSection from '../_COMPONENTS/Navbar/Navbar.jsx';
import FooterSection from '../_COMPONENTS/Footer/Footer';
import MainSection from './HomePage/MainSection/MainSection';
//Sections
//CSS


export default function HomePage() {
    return (
        <>
            <PageInformation id='PageInformation' pageName='Home' />
            <NavbarSection id='HomePageNavbarSection' />
            <MainSection id='MainSection' />
            <FooterSection id='FooterSection' />
        </>
    );

}