import React, { Component } from "react";

export default class MobileMainSection extends Component {
  state = {};
  render() {
    return (
      <>
        <ol class="list-group list-group-numbered">
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Subheading</div>
              Content for list item
            </div>
            <span class="badge bg-primary rounded-pill">14</span>
          </li>
        </ol>
      </>
    );
  }
}
