import React, { Component } from "react";

export default class MobileMainSection extends Component {
  state = {};
  render() {
    return (
      <>
        <div id="Mobile_AboutPageMainSection" style={{ maxWidth: "390px" }}>
          <div>
            <h1>if you are able to read this message:</h1>
            <h2>
              <ul>
                <li>Mobile View is currently unsupported.</li>
                <li>
                  Please decrease page zoom to 50% to see contents of this page.
                </li>
              </ul>
            </h2>
            {/* 
          <div>
            <div className="Mobile_Introduction">
              <div className="Mobile_AboutPageIntro">
                <p>
                  Here at BorDev Tech we provide top-notch service to get you on
                  your feet and ready to go!
                </p>
                <p>
                  Our wide range of experience helps us, help you in building
                  anything you can dream of.
                </p>
              </div>
              <div className="Mobile_AboutPageIntroButtonSection">
                <button className="Mobile_AboutPageIntroButtons">
                  <Link
                    to="/signup"
                    className="Mobile_AboutPageIntroButtonLink"
                  >
                    Get Started
                  </Link>
                </button>
                <hr />
                <button className="Mobile_AboutPageIntroButtons">
                  <Link
                    to="/pricing"
                    className="Mobile_AboutPageIntroButtonLink"
                  >
                    Pricing
                  </Link>
                </button>
              </div>
            </div>
          </div> 
          */}
          </div>
        </div>
      </>
    );
  }
}
