import React from "react";
import "../css/Main.css";

function MainSection() {
  return (
    <div id="MainSection">
      <div>
        <h2>&lt;Calender&gt;</h2>
        {/*Starts with Daily Section for Current Viewers*/}
        <ul className="MainSectionList">
          <li>
            Analytics
            <ul>
              <li>
                Account Administration
                <ul>
                  <li>Site Speed</li>
                  <li>A/B Testing</li>
                  <li>Content Performance</li>
                  <li>Event Tracking Performance</li>
                  <li>Conversion Performance</li>
                </ul>
              </li>
              <li>
                Reports
                <ul>
                  <li>
                    Demographics Reports
                    <ul>
                      <li>Language</li>
                      <li>Age</li>
                      <li>Gender</li>
                      <li>Location</li>
                    </ul>
                  </li>
                  <li>
                    Behavior Reports
                    <ul>
                      <li>User Flow</li>
                      <li>User Engagement</li>
                      <li>User Frequency/Recency</li>
                      <li>New Users vs Returning Users</li>
                    </ul>
                  </li>
                  <li>Technology Reports</li>
                  <li>Conversion Reports</li>
                </ul>
              </li>
              <li>
                Acquisition
                <ul>
                  <li>Traffic Campaigns</li>
                  <li>Social Campaigns</li>
                  <li>
                    Campaign Health
                    <ul>
                      <li>Cost Analysis</li>
                      <li>Paid Keywords</li>
                      <li>Organic Keywords</li>
                    </ul>
                  </li>
                  <li>
                    Search Campaigns
                    <ul>
                      <li>Queries</li>
                      <li>Search Devices</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Conversions
                <ul>
                  <li>Converion Goals</li>
                  <li>
                    Ecommerce
                    <ul>
                      <li>Product Performance</li>
                      <li>Sales Performance</li>
                      <li>Transactions / TTP(Time to Purchase)</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
}

export default MainSection;
