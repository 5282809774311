import React, { Component } from "react";

import { Link, Outlet } from "react-router-dom";

export default class ThePlan extends Component {
  state = {};
  render() {
    return (
      <div className="Global_AboutPageSection3Card">
        <div className="Global_Introduction">
          <ul className="Global_AboutPageSectionOptionsList">
            <button className="Global_AboutPageSectionOptionsListOption 1">
              <Link to="dream">The Dream</Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 2">
              <Link to="team">
                <p>The Team</p>
              </Link>
            </button>
            <button
              className="Global_AboutPageSectionOptionsListOption 3"
              disabled
            >
              <Link to="plan">
                <p>The Plan</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 4">
              <Link to="work">
                <p>The Work</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 5">
              <Link to="tools">
                <p>The Tools</p>
              </Link>
            </button>
            <button className="Global_AboutPageSectionOptionsListOption 6">
              <Link to="results">
                <p>The Results</p>
              </Link>
            </button>
            <Outlet />
          </ul>
          <div style={{ border: "1px solid", width: "90%", height: "auto" }}>
            <div> hi </div>
          </div>
        </div>

        <h2 className="Global_AboutPageSection3CardTitle">The Plan</h2>
        <div className="TestBlock">
          <div>
            <img
              scr="#"
              alt="Section5Image"
              className="Global_AboutPageSection5PointImage"
            ></img>
            <p className="Global_AboutPageSection5PointTitle">Step 3: Plan</p>
            <p className="Global_AboutPageSection5PointBioShort">
              With our filter in place, making a outline to mark our path with
              S.M.A.R.T. goals to complete.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
