import React from 'react';
//Components
import PageInformation from '../_COMPONENTS/PageInformation/PageInformation';
import NavbarSection from '../_COMPONENTS/Navbar/Navbar';
import FooterSection from '../_COMPONENTS/Footer/Footer';
import MainSection from './ProductsPage/components/mainSection.jsx';
//Sections
//CSS
import './ProductsPage/page/ProductsPage.css'


export default function ProductsPage() {
    return (
        <>
            <PageInformation id='PageInformation' pageName='Products' />
            {/*Sections */}
            <NavbarSection id='NavbarSection' />
            <MainSection id='MainSection' />
            <FooterSection id='FooterSection' />
        </>
    );

}