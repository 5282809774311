import React, { Component } from "react";
import PricingCard from "../PricingCard/PriceCard";

export default class PremiumPackage extends Component {
  state = {
    data: [
      {
        Package: [
          {
            Level: "Premium",
            Description:
              "Stand out with an e-commerce website, premium design, & advanced SEO. A must for growing businesses.",
          },
        ],
        Data: [
          {
            Price: "449",
            PayFrequency: "month",
          },
        ],
        Features: [
          { Title: "", Content: "Custom design" },
          { Title: "", Content: "Advanced on-page SEO" },
          { Title: "", Content: "E-commerce integration" },
          { Title: "", Content: "Custom lead generation forms" },
          { Title: "", Content: "Advanced analytics and tracking" },
          { Title: "", Content: "Priority support" },
        ],
        Action: [{ ButtonTitle: "Sign Up", PushPath: "premium" }],
      },
    ],
  };
  render() {
    const { Package, Data, Features, Action } = this.state.data[0];
    return (
      <>
        <div className="col">
          <PricingCard
            Package={Package}
            Data={Data}
            Features={Features}
            Action={Action}
          />
        </div>
      </>
    );
  }
}
