// REQ Imports
import React, { Component } from "react";
// Section Cards
import "bootstrap/dist/css/bootstrap.css";

export default class GlobalMainSection extends Component {
  state = {
    Data: {
      ViewMode: {
        Global: {
          Size: "Global_",
          ViewportSize: {},
          Style: {},
        },
      },
      links: ["team", "plan", "work", "tools", "results"],
      names: ["The Team", "The Plan", "The Work", "The Tools", "The Results"],
      PlaceholderImg: {
        ImgStyle: { maxHeight: "258px", width: "1853px" },
        ImgSize: `${`315`}x${`253`}`,
      },
    },
  };

  render() {
    const { links, names, ViewMode, PlaceholderImg } = this.state.Data;
    const linkList = links;
    const nameList = names;
    const { ImgStyle, ImgSize } = PlaceholderImg;
    const { Size } = ViewMode.Global;
    return (
      <>
        <div id={`${Size}AboutPageMainSection`}>
          <h1 className={`${Size}AboutPageMainSectionSize`}>About Us</h1>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="dream-tab"
                data-bs-toggle="tab"
                data-bs-target="#dream-tab-pane"
                type="button"
                role="tab"
                aria-controls="dream-tab-pane"
                aria-selected="true"
              >
                The Dream
              </button>
            </li>

            {nameList.map((link, key) => (
              <li className="nav-item" key={key} role="presentation">
                <button
                  className="nav-link btn btn-primary"
                  id={`${linkList[key]}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${linkList[key]}-tab-pane`}
                  type="button"
                  role="tab"
                  aria-controls={`${linkList[key]}-tab-pane`}
                  aria-selected="true"
                >
                  {nameList[key]}
                </button>
              </li>
            ))}
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="dream-tab-pane"
              role="tabpanel"
              aria-labelledby="dream-tab"
              tabIndex="0"
            >
              <div className="card text-bg-dark">
                <img
                  src={`https://via.placeholder.com/${ImgSize}.png`}
                  className="card-img"
                  alt="..."
                  style={ImgStyle}
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">Dream</h5>
                  <p className="card-text">
                    Our DREAM is to help business owners and individuals Lorem
                    Architecto, quo, ut quis voluptates nisi similique ab
                    incidunt iure vitae officia debitis laboriosam distinctio
                    sequi fugiat consectetur explicabo dolore ex esse.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="team-tab-pane"
              role="tabpanel"
              aria-labelledby="team-tab"
              tabIndex="0"
            >
              <div className="card text-bg-dark">
                <img
                  src={`https://via.placeholder.com/${ImgSize}.png`}
                  className="card-img"
                  alt="..."
                  style={ImgStyle}
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">team</h5>
                  <p className="card-text">
                    Our Team is Lorem Architecto, quo, ut quis voluptates nisi
                    similique ab incidunt iure vitae officia debitis laboriosam
                    distinctio sequi fugiat consectetur explicabo dolore ex
                    esse.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="plan-tab-pane"
              role="tabpanel"
              aria-labelledby="plan-tab"
              tabIndex="0"
            >
              <div className="card text-bg-dark">
                <img
                  src={`https://via.placeholder.com/${ImgSize}.png`}
                  className="card-img"
                  alt="..."
                  style={ImgStyle}
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">Plan</h5>
                  <p className="card-text">
                    Our PLAN is to help business owners and individuals Lorem
                    Architecto, quo, ut quis voluptates nisi similique ab
                    incidunt iure vitae officia debitis laboriosam distinctio
                    sequi fugiat consectetur explicabo dolore ex esse.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="work-tab-pane"
              role="tabpanel"
              aria-labelledby="work-tab"
              tabIndex="0"
            >
              <div className="card text-bg-dark">
                <img
                  src={`https://via.placeholder.com/${ImgSize}.png`}
                  className="card-img"
                  alt="..."
                  style={ImgStyle}
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">work</h5>
                  <p className="card-text">
                    Our WORK is to help business owners and individuals Lorem
                    Architecto, quo, ut quis voluptates nisi similique ab
                    incidunt iure vitae officia debitis laboriosam distinctio
                    sequi fugiat consectetur explicabo dolore ex esse.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tools-tab-pane"
              role="tabpanel"
              aria-labelledby="tools-tab"
              tabIndex="0"
            >
              <div className="card text-bg-dark">
                <img
                  src={`https://via.placeholder.com/${ImgSize}.png`}
                  className="card-img"
                  alt="..."
                  style={ImgStyle}
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">tools</h5>
                  <p className="card-text">
                    Our TOOLS is to help business owners and individuals Lorem
                    Architecto, quo, ut quis voluptates nisi similique ab
                    incidunt iure vitae officia debitis laboriosam distinctio
                    sequi fugiat consectetur explicabo dolore ex esse.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="results-tab-pane"
              role="tabpanel"
              aria-labelledby="results-tab"
              tabIndex="0"
            >
              <div className="card text-bg-dark">
                <img
                  src={`https://via.placeholder.com/${ImgSize}.png`}
                  className="card-img"
                  alt="..."
                  style={ImgStyle}
                />
                <div className="card-img-overlay">
                  <h5 className="card-title">results</h5>
                  <p className="card-text">
                    Our RESULTS is to help business owners and individuals Lorem
                    Architecto, quo, ut quis voluptates nisi similique ab
                    incidunt iure vitae officia debitis laboriosam distinctio
                    sequi fugiat consectetur explicabo dolore ex esse.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
