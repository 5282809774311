import React, { Component } from "react";
import PricingCard from "../PricingCard/PriceCard";

export default class BasicPackage extends Component {
  state = {
    data: [
      {
        Package: [
          {
            Level: "Basic",
            Description:
              "For small businesses or individuals looking for a simple online presence.",
          },
        ],
        Data: [
          {
            Price: "199",
            PayFrequency: "One-Time",
          },
        ],
        Features: [
          {
            Title: "Feature",
            Content:
              "A small number of web pages (e.g. home page, about us, contact us)",
          },
          { Title: "Feature", Content: "A basic design template" },
          { Title: "Feature", Content: "Basic on-page SEO" },
        ],
        Action: [{ ButtonTitle: "Sign Up", PushPath: "basic" }],
      },
    ],
  };
  render() {
    const { Package, Data, Features, Action } = this.state.data[0];
    return (
      <>
        <div className="col">
          <PricingCard
            Package={Package}
            Data={Data}
            Features={Features}
            Action={Action}
          />
        </div>
      </>
    );
  }
}
