import React from "react";

import CardTitle from "./cardTitle";
import CardBody from "./cardBody";
import CardFooter from "./cardFooter";

const Card = ({ Title, Data, Path, Direction, TLD, LastUpdate }) => {
  return (
    <>
      <div className="col">
        <div className="card text-center">
          <CardTitle
            Title={Title}
            Path={Path}
            Direction={Direction}
            TLD={TLD}
          />
          <CardBody Data={Data} />
          <CardFooter LastUpdate={LastUpdate} />
        </div>
      </div>
    </>
  );
};
export default Card;
