//IMPORTS
import React, { Component } from "react";

//////////////////////////////////////////////////////
// VIEWPORT SIZES CSS
/* CSS */
import "./Views/CSS/Footer.css";
/* Global View */
import GlobalFooter from "./Views/GlobalFooter.jsx";
/* Tablet View */
import TabletFooter from "./Views/TabletFooter.jsx";
/* Mobile View */
import MobileFooter from "./Views/MobileFooter.jsx";
//////////////////////////////////////////////////////
export default class FooterSection extends Component {
  state = {};
  render() {
    return (
      <>
        <GlobalFooter id="GlobalFooter" />
        <TabletFooter id="TabletFooter" />
        <MobileFooter id="MobileFooter" />
      </>
    );
  }
}
