import React, { Component } from "react";

import GlobalDream from "./Views/Global-Dream";
//Sections

export default class TheDream extends Component {
  state = {};
  render() {
    return (
      <>
        <GlobalDream />
        {/* <TabletDream/>
            <MobileDream/> */}
      </>
    );
  }
}
