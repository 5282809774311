import TeamCard from "../../../../_COMPONENTS/Specials/TeamCard/TeamCard";

export default function Tablet_MainSection() {
  return (
    <div id="CompanyPageMainSection">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{ border: "1px solid black", width: "80%", height: "150px" }}
          alt="he"
        />
        <div>
          <h4>What</h4>
          <div>
            <p>what skills do we have for which services/products we serve</p>
            <p>what is it this for</p>
            <p>what else can be done after use</p>
          </div>
        </div>
        <div>
          <h4>Why</h4>
          <ul>
            <p>what made us choose this path</p>
            <p>Why we want you to come to us instead of somewhere else</p>
            <p>adw</p>
          </ul>
        </div>
        <div>
          <h4>When</h4>
          <div> what time do/dont we do this</div>
        </div>
        <div>
          <h4>Who </h4>
          <TeamCard id="test" name="Devell" position="Owner" />
          <TeamCard name="Dani" position="Owner" />

          <div>what type of people are we, what type of people do we serve</div>
        </div>
        <div>
          <h4>Where </h4>
          <div>
            what place can we have our/your products be displayed, used, and
            stored
          </div>
        </div>
      </div>
      <div id="Test">b</div>
      <div>Careers</div>
    </div>
  );
}
