//IMPORTS
import React, { Component } from "react";
//////////////////////////////////////////////////////
// VIEWPORT SIZES CSS
/* CSS */
import "./Views/CSS/Navbar.css";
/* Global View */
import GlobalNavbar from "./Views/GlobalNavbar.jsx";
/* Tablet View */
import TabletNavbar from "./Views/TabletNavbar.jsx";
/* Mobile View */
import MobileNavbar from "./Views/MobileNavbar.jsx";
//////////////////////////////////////////////////////

export default class NavbarSection extends Component {
  state = {
    Data: {
      ViewMode: {
        Global: {
          ViewportMax: {},
        },
        Tablet: {
          ViewportMax: { maxWidth: "768px" },
        },
        Mobile: {
          ViewportMax: { maxWidth: "390px" },
        },
      },
    },
  };
  render() {
    // const { Global, Tablet, Mobile } = this.state.Data.ViewMode;
    return (
      <>
        <GlobalNavbar id="GlobalNavbar" />
        <TabletNavbar id="TabletNavbar" />
        <MobileNavbar id="MobileNavbar" />
      </>
    );
  }
}
