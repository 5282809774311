import React, { Component } from "react";

import { Link } from "react-router-dom";
// import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
// import NavLinksList from "./Parts/navLinks.js";
import PageRoutes from "./Parts/pageRoutes.js";
import DarkModeSwitch from "./../../Specials/DarkModeSwitch/DarkModeSwitch";
import SearchBar from "./../../Specials/SearchBars/MainSearchBar/searchBar";

class MobileNavbar extends Component {
  state = {};
  render() {
    return (
      <>
        <div
          id="Mobile_NavbarSection"
          className="list-group list-group-flush list-group-item"
        >
          <nav className="card navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
              <Link className="navbar-brand m-2" to={"/"}>
                BorDev Tech
              </Link>
              <button
                className="navbar-toggler m-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto m-2 ">
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/about"}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/services"}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/projects"}
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/contact"}
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <SearchBar />
                  </li>
                  <li>
                    <DarkModeSwitch
                      elmId="root"
                      lightModeColor="white"
                      darkModeColor="#1C1C1E"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        {/* 
        Mobile View
        <div className="Mobile_HomePageNavbarSection1">
          <Link className="Mobile_HomePageLink" to={"/"}>
            <p id="Mobile_HomePageCompanyTitle">BorDev Tech</p>
          </Link>
        </div>
        {open ? MenuClose : MenuOpen}
        {open && <NavLinksList />}
        
            <ul className="Mobile_HomePageNavbarSection2">
                <li className="Mobile_HomePageNavbarSection2ListItem">
                    <Link to={'/about'} className="Mobile_HomePageNavLink">
                        <p className='Mobile_HomePageNavLinkTitle'>About</p>
                    </Link>
                </li>
                <li className="Mobile_HomePageNavbarSection2ListItem">
                    <Link to={'/services'} className="Mobile_HomePageNavLink">
                        <p className='Mobile_HomePageNavLinkTitle'>Services</p>
                    </Link>
                </li>
                <li className="Mobile_HomePageNavbarSection2ListItem">
                    <Link to={'/portfolio'} className="Mobile_HomePageNavLink">
                        <p className='Mobile_HomePageNavLinkTitle'>Portfolio</p>
                    </Link>
                </li>
                <li className="Mobile_HomePageNavbarSection2ListItem">
                    <Link to={'/contact'} className="Mobile_HomePageNavLink">
                        <p className='Mobile_HomePageNavLinkTitle'>Contact</p>
                    </Link>
                </li>
                <li className='Mobile_getStartedItem'>
                    <button className="Mobile_HomePageGetStarted">
                        <p className='Mobile_HomePageGetStartedNavLink'>Get Started</p>
                    </button>
                </li>
            </ul>
      </div> */}
      </>
    );
  }
}
export default MobileNavbar;
