import React, { Component } from "react";

class SearchInput extends Component {
  state = {};
  render() {
    const { Data } = this.props;
    return (
      <>
        <input
          className="form-control me-2"
          type="search"
          list="datalistOptions"
          placeholder="Search"
          aria-label="Search"
        />
      </>
    );
  }
}

export default SearchInput;
