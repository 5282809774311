import React, { Component } from 'react';

import { Link } from "react-router-dom";
import "../pages/ConstructionPage/page/ConstructionPage.css";

export default class RedirectMap extends Component {
  state = {}
  render() {
    return (
      <>
        <div className="Atlas">
          <div>
            <ul className="MainPoint">
              <li>
                <Link to={"/"}>
                  <button id='SubPointMenuItem'>🟢Home🟢</button>
                </Link>
                <ul className="SubPoint">
                  <li className="SubPointMenu">
                    <Link to={"/about"}>
                      <button id='SubPointMenuItem'>🟡About🟡</button>
                    </Link>
                  </li>

                  <li className="SubPointMenu">
                    <div id="ServicesListDirectoryButton">
                      <Link to={"/services"}>
                        <button id='SubPointMenuItem'>🟡Services🟡</button>
                      </Link>
                      <ul id="ServicesList">
                        {/*<li><button>3D Art/Design</button>
                                          <ul>
                                              <li><button>Neon Shop Lights</button></li>
                                          </ul>
                                      </li>*/}
                        {/*<li><button>App Development</button></li>*/}
                        {/*<li><button>Software Development</button></li>*/}
                        <li id="ServicesListItem">
                          <button>Game Development</button>
                        </li>
                        <li id="ServicesListItem">
                          <button>Mathematical Formulations/Calculators</button>
                          <ul id="ServicesListSubMenuList">
                            <li id="ServicesListSubItem">
                              <button>Income Calculator</button>
                            </li>
                            <li id="ServicesListSubItem">
                              <button>Expense Calculator</button>
                            </li>
                          </ul>
                        </li>
                        <li id="ServicesListItem">
                          <button>Web Development</button>
                          <ul id="ServicesListSubMenuList">
                            <li id="ServicesListSubItem">
                              <button>Website Building</button>
                            </li>
                          </ul>
                        </li>
                        <li id="ServicesListItem">
                          <button>Developer for hire</button>
                          <ul id="ServicesListSubMenuList">
                            <li id="ServicesListSubItem">
                              <button>Discord Bots</button>
                            </li>
                            <li id="ServicesListSubItem">
                              <button>Coding Revisions / Correction</button>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="SubPointMenu">
                    <div id="PortfolioDirectoryButton">
                      <Link to={"/portfolio"}>
                        <button id='SubPointMenuItem'>🟡Portfolio🟡</button>
                      </Link>
                      <ul id="PortfolioList">
                        <li>
                          <button>&lt;&gt;</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="SubPointMenu">
                    <div id="ContactDirectoryButton">
                      <Link to={"/contact"}>
                        <button id='SubPointMenuItem'>🟡Contact🟡</button>
                      </Link>
                      <ul id="ContactList">
                        <li>
                          <button>&lt;&gt;</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="SubPointMenu">
                    <div id="CompanyDirectoryButton">
                      <Link to={"/company"}>
                        <button id='SubPointMenuItem'>🟡Company🟡</button>
                      </Link>
                      <ul id="CompanyList">
                        <li>
                          <button>Careers</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="SubPointMenu">
                    <div id="SupportListDirectoryButton">
                      <Link to={"/support"}>
                        <button id='SubPointMenuItem'>🟡Support🟡</button>
                      </Link>
                      <ul id="SupportList">
                        <li>
                          <button>Guides</button>
                        </li>
                      </ul>
                    </div>

                  </li>
                  <li className="SubPointMenu">
                    <div id="ProductsListDirectoryButton">
                      <Link to={"/products"}>
                        <button id='SubPointMenuItem'>🟡Products🟡</button>
                      </Link>
                      <ul id="ProductsList">
                        {/*<li><button>3D Creations</button></li>*/}
                        <li>
                          <button>Discord Bots</button>
                        </li>
                        {/*<li><button>APIs</button></li>*/}
                        <li>
                          <button>Website Building</button>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="SubPointMenu">
                    <div id="SocialsListDirectoryButton">
                      <Link to={"/socials"}>
                        <button id='SubPointMenuItem'><div>🟡Socials🟡</div></button>
                      </Link>
                      <ul id="SocialsList">
                        <li>
                          <button>Facebook</button>
                        </li>
                        <li>
                          <button>Instagram</button>
                        </li>
                        <li>
                          <button>Twitter</button>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="SubPointMenu">
                    <div id="SponsorsListDirectoryButton">
                      <Link to={"/sponsors"}>
                        <button id='SubPointMenuItem'><div>🟡Sponsors🟡</div></button>
                      </Link>
                      <ul id="SponsorsList">
                        <li>
                          <button>&lt;&gt;</button>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div><hr />

        </div><div className="Legend">
          Legend:
          <ul>
            <li>
              🔴: Rebranded<sup>1</sup> or Permenantly Removed<sup>2</sup>
            </li>
            <li>🟡: Under Construction</li>
            <li>🟢: Up and Running</li>
            <li>
              <sup>1</sup>- Page endpoint has been changed to a shorter endpoint
              or combined with another. see <a href="/">changelog</a>
            </li>
            <li>
              <sup>2</sup>- Page endpoint has been removed due to complication
              of service or interruptions . see <a href="/">changelog</a>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
