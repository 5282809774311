import React, { Component } from "react";
import Card from "../../../../../_COMPONENTS/Common/SectionCards/card";

export default class SocialsSectionCard extends Component {
  state = {
    data: [
      {
        Name: [
          {
            Header: "Socials",
            Path: "socials",
            Direction: "INBOUND",
            TLD: "/",
          },
        ],
        Data: [
          {
            Title: "GitHub",
            Path: "github",
            Direction: "OUTBOUND",
            TLD: ".com/sponsors/BorDevTech?o=esb",
          },
          {
            Title: "Instagram",
            Path: "instagram",
            Direction: "OUTBOUND",
            TLD: ".com",
          },
          {
            Title: 'Meta "Facebook"',
            Path: "facebook",
            Direction: "OUTBOUND",
            TLD: ".com",
          },
          {
            Title: "Telegram",
            Path: "telegram",
            Direction: "OUTBOUND",
            TLD: ".org",
          },
        ],
        Info: [{ LastUpdate: " Last updated 3 mins ago " }],
      },
    ],
  };
  render() {
    const { Name, Data, Info } = this.state.data[0];
    console.log(`Name: ${Name}\n Data: ${Data}\n Info: ${Info}`);
    const { Header, Path, Direction, TLD } = Name[0];
    const { LastUpdate } = Info[0];
    return (
      <>
        <Card
          Title={Header}
          Data={Data}
          Path={Path}
          Direction={Direction}
          TLD={TLD}
          LastUpdate={LastUpdate}
        />
      </>
    );
  }
}
